import { amgApi } from '@/service/axios'

class SpecialistsDashboardService {
  async getCardsData(params) {
    try {
      const { data } = await amgApi.post('specialists-digital/get-cards-data', params)
      return data
    } catch (error) {
      console.error('Something went wrong on getPendingDirectRoundCount', error)
    }
  }
  async getChartData(params) {
    try {
      const { data } = await amgApi.post('specialists-digital/get-chart-data', params)
      return data
    } catch (error) {
      console.error('Something went wrong on getPendingDirectRoundCount', error)
    }
  }
  async getModalData(params) {
    try {
      const data = await amgApi.post('specialists-digital/get-modal-data', params)
      return data
    } catch (error) {
      console.error('Something went wrong on getPendingDirectRoundCount', error)
    }
  }
}
export default new SpecialistsDashboardService()
