var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"lg","title":_vm.modalTitle,"title-tag":"h3","hide-footer":"","modal-class":"modal-primary","scrollable":""},on:{"hidden":_vm.closeModal},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('b-tabs',{attrs:{"pills":"","lazy":"","nav-class":"mt-2","active-nav-item-class":"bg-primary box-shadow-info"},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[(_vm.type == 'ncr')?[_c('b-tab',{attrs:{"title-link-class":[_vm.bgTabsNavs, 'sub-tab px-3'],"title":"NCR Requested"}},[_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"aria-controls":"ncr-requested"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('b-table',{attrs:{"id":"ncr-requested","per-page":_vm.perPage,"current-page":_vm.currentPage,"items":_vm.tableProvider,"fields":_vm.fields,"show-empty":""},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('router-link',{class:_vm.textLink,attrs:{"to":{
                name: 'specialist-digital-dashboard',
                params: { idClient: ("" + (data.item.client_id)) },
              },"target":"_blank"}},[_vm._v(_vm._s(data.value)+" ")])]}},{key:"cell(date)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm._f("myGlobal")(data.value)))])]}}],null,false,918507487)})],1),_c('b-tab',{attrs:{"title-link-class":[_vm.bgTabsNavs, 'sub-tab px-3'],"title":"NCR Processed"}},[_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"aria-controls":"ncr-procesed"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('b-table',{attrs:{"id":"ncr-procesed","per-page":_vm.perPage,"current-page":_vm.currentPage,"items":_vm.tableProvider,"fields":_vm.fields,"show-empty":""},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('router-link',{class:_vm.textLink,attrs:{"to":{
                name: 'specialist-digital-dashboard',
                params: { idClient: ("" + (data.item.client_id)) },
              },"target":"_blank"}},[_vm._v(_vm._s(data.value)+" ")])]}},{key:"cell(date)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm._f("myGlobal")(data.value)))])]}}],null,false,918507487)})],1)]:(_vm.type == 'work')?[_c('b-tab',{attrs:{"title":"Work Plan","title-link-class":"mytab"}},[_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"aria-controls":"work-plan"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('b-table',{attrs:{"id":"work-plan","per-page":_vm.perPage,"current-page":_vm.currentPage,"items":_vm.tableProvider,"fields":_vm.fields,"show-empty":""},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('router-link',{class:_vm.textLink,attrs:{"to":{
                name: 'specialist-digital-dashboard',
                params: { idClient: ("" + (data.item.client_id)) },
              },"target":"_blank"}},[_vm._v(_vm._s(data.value)+" ")])]}},{key:"cell(date)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm._f("myGlobal")(data.value)))])]}}])})],1)]:(_vm.type == 'dispute')?[_c('b-tab',{attrs:{"title":"Dispute Requested","title-link-class":"mytab"}},[_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"aria-controls":"dispute-all"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('b-table',{attrs:{"id":"dispute-all","per-page":_vm.perPage,"current-page":_vm.currentPage,"items":_vm.tableProvider,"fields":_vm.fields,"show-empty":""},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('router-link',{class:_vm.textLink,attrs:{"to":{
                name: 'specialist-digital-dashboard',
                params: { idClient: ("" + (data.item.client_id)) },
              },"target":"_blank"}},[_vm._v(_vm._s(data.value)+" ")])]}},{key:"cell(date)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm._f("myGlobal")(data.value)))])]}}])})],1),_c('b-tab',{attrs:{"title":"Dispute Processed","title-link-class":"mytab"}},[_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"aria-controls":"dispute-completed"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('b-table',{attrs:{"id":"dispute-completed","per-page":_vm.perPage,"current-page":_vm.currentPage,"items":_vm.tableProvider,"fields":_vm.fields,"show-empty":""},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('router-link',{class:_vm.textLink,attrs:{"to":{
                name: 'specialist-digital-dashboard',
                params: { idClient: ("" + (data.item.client_id)) },
              },"target":"_blank"}},[_vm._v(_vm._s(data.value)+" ")])]}},{key:"cell(date)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm._f("myGlobal")(data.value)))])]}}])})],1)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }