<template>
  <div>
    <header-slot />
    <b-card no-body>
      <b-row class="p-1">
        <b-col v-if="isCeo || isSupervisor" md="3" sm="3">
          <div class="ml-2">
            <b-form-group label="Name" label-for="input-formatter" class="mb-0">
              <v-select
                v-model="userSelected"
                :reduce="(option) => option.id"
                :options="users"
                label="user_name"
                @input="updateValues"
              />
            </b-form-group>
          </div>
        </b-col>
        <b-col md="3" sm="3">
          <div class="ml-2">
            <b-form-group label="Date from" label-for="date-from" class="mb-0">
              <b-form-datepicker
                v-model="dateFrom"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              />
            </b-form-group>
          </div>
        </b-col>
        <b-col md="3" sm="3">
          <div class="ml-2">
            <b-form-group label="Date to" label-for="date-to" class="mb-0">
              <b-form-datepicker
                v-model="dateTo"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              />
            </b-form-group>
          </div>
        </b-col>
        <b-col md="auto" sm="auto" class="mt-2">
          <div class="d-inline-flex">
            <b-button
              variant="info"
              class="btn-icon mr-1"
              @click="updateValues"
            >
              <feather-icon icon="RefreshCcwIcon" />
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card-group deck class="mb-2">
      <b-card
        v-for="(i, index) in cardsData"
        :key="index"
        no-body
        :style="`border: 1px solid ${i.color}!important`"
        :class="i.cursor ? 'cursor-pointer' : ''"
        class="card"
        @click="activateModal(index)"
      >
        <b-card-body>
          <div class="d-flex align-items-end justify-content-between">
            <span class="mb-25 mt-1 title text-uppercase">
              {{ i.title }}
            </span>
            <b-avatar
              :style="`${i.back}; color: ${i.color}`"
              size="4em"
              class="icon"
            >
              <feather-icon size="21" :icon="i.icon" />
            </b-avatar>
          </div>
          <div v-if="index === 1">
            <div class="mt-2 mb-1">
              <span class="title-mobile"> PLANIFIED: </span>
              <span class="data"> {{ i.work }} </span>
            </div>
          </div>
        </b-card-body>
        <div
          v-if="index === 0"
          class="d-inline-flex w-100 mb-2"
          :style="`border-top: 1px solid ${i.color}!important;border-bottom: 1px solid ${i.color}!important`"
        >
          <div
            class="square"
            style="width: 50%; padding-top: 7px; padding-bottom: 7px"
            :style="`border-right: 1px solid ${i.color} !important;`"
          >
            <span class="title-mobile" style="margin-left: 20px">
              REQUESTED:
            </span>
            <span class="data-mobile" style="margin-left: 7px">{{
              i.ncrRequested ? i.ncrRequested : 0
            }}</span>
          </div>
          <div style="width: 50%; padding-top: 7px; padding-bottom: 7px">
            <span style="margin-left: 20px" class="title-mobile">
              PROCESSED:
            </span>
            <span class="data-mobile" style="margin-left: 6px"
              >{{ i.ncrProcessed ? i.ncrProcessed : 0 }}
            </span>
          </div>
        </div>
        <div
          v-if="index === 2"
          class="d-inline-flex w-100 mb-2"
          :style="`border-top: 1px solid ${i.color}!important;border-bottom: 1px solid ${i.color}!important`"
        >
          <div
            class="square"
            style="width: 50%; padding-top: 7px; padding-bottom: 7px"
            :style="`border-right: 1px solid ${i.color} !important;`"
          >
            <span class="title-mobile" style="margin-left: 20px">
              REQUESTED:
            </span>
            <span class="data-mobile" style="margin-left: 7px">{{
              i.disputeAll ? i.disputeAll : 0
            }}</span>
          </div>
          <div style="width: 50%; padding-top: 7px; padding-bottom: 7px">
            <span style="margin-left: 20px" class="title-mobile">
              PROCESSED:
            </span>
            <span class="data-mobile" style="margin-left: 6px"
              >{{ i.disputeCompleted ? i.disputeCompleted : 0 }}
            </span>
          </div>
        </div>
      </b-card>
    </b-card-group>
    <div class="m-0">
      <b-card>
        <line-sp-dashboard class="w-100" :options-data="optionsData" />
      </b-card>
    </div>
    <custom-modal
      v-if="showCustomModal"
      :type="modalType"
      :params="formattedParams"
      @close="showCustomModal = false"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";
import LineSpDashboard from "@/views/specialist-digital/views/dashboard/components/LineSpDashboard.vue";
import ListSeriesSpDashboard from "@/views/specialist-digital/views/dashboard/components/ListSeriesSpDashboard.vue";
import SpecialistsDashboardService from "@/views/specialist-digital/views/dashboard/specialists-dashboard-service";
import GlobalService from "@/service/global/index";
import moment from "moment";
import { mapGetters } from "vuex";
import CustomModal from "@/views/specialist-digital/views/dashboard/components/CustomModal.vue";

export default {
  components: {
    vSelect,
    LineSpDashboard,
    ListSeriesSpDashboard,
    CustomModal,
  },
  data() {
    return {
      cardsData: [
        {
          title: "NCR",
          ncrRequested: 0,
          ncrProcessed: 0,
          color: "#FF9F43",
          back: "background-color: rgba(255, 159, 67, 0.12)!important",
          icon: "TrendingUpIcon",
          cursor: true,
        },
        {
          title: "WORK PLAN",
          work: 0,
          color: "#0090e7",
          back: "background: rgba(0,145,230,0.12)!important",
          icon: "TrendingUpIcon",
          cursor: true,
        },
        {
          title: "UPDATE REQUEST",
          disputeCompleted: 0,
          disputeAll: 0,
          color: "#EA5455",
          back: "background-color: rgba(252, 66, 74, 0.12)!important",
          icon: "TrendingUpIcon",
          cursor: true,
        },
      ],
      dateFrom: "",
      dateTo: "",
      users: [
        {
          id: null,
          user_name: "All Users",
        },
      ],
      userSelected: null,
      seriesData: [
        {
          name: "NCR Requested",
          color: "#eead92",
        },
        {
          name: "NCR Processed",
          color: "#e2754a",
        },
        {
          name: "Works Plan",
          color: "#4bc0ea",
        },
        {
          name: "Dispute Requested",
          color: "#f39897",
        },
        {
          name: "Dispute Processed",
          color: "#e84d4d",
        },
      ],
      datesArray: [],
      optionsData: {
        datesXAxis: [],
        dataNcrRequested: [],
        dataNcrProcessed: [],
        dataWorkPlan: [],
        dataDisputeRequested: [],
        dataDisputeProcessed: [],
      },
      showCustomModal: false,
      modalType: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    formattedParams() {
      return {
        from: this.dateFrom,
        to: this.dateTo,
        user: this.userSelected,
      };
    },
  },
  async created() {
    this.dateFrom = moment().format("YYYY-MM-DD");
    this.dateTo = moment().format("YYYY-MM-DD");
    if (this.isCeo || this.isSupervisor) {
      this.userSelected = null;
    } else {
      this.userSelected = this.currentUser.user_id;
    }
    await this.getCardsData();
    await this.getUsersData();
    await this.getChartsData();
  },
  methods: {
    async getCardsData() {
      const data = await SpecialistsDashboardService.getCardsData({
        user: this.userSelected,
        from: this.dateFrom,
        to: this.dateTo,
        client_type_id: 2,
      });
      this.cardsData[0].ncrRequested = data.ncr_requested;
      this.cardsData[0].ncrProcessed = data.ncr_processed;
      this.cardsData[1].work = data.work_plan;
      this.cardsData[2].disputeCompleted = data.dispute_results;
      this.cardsData[2].disputeAll = data.dispute_all;
    },
    async getUsersData() {
      const data = await GlobalService.getSellers(11, {
        roles: "[]",
        type: "1",
      });
      if (data.status === 200) {
        this.users = [];
        this.users = [...this.users, ...data.data];
      }
    },
    async getChartsData() {
      const dataNcrRequested = await SpecialistsDashboardService.getChartData({
        from: this.dateFrom,
        to: this.dateTo,
        type: 1,
        user: this.userSelected,
        client_type_id: 2,
      });
      this.optionsData.datesXAxis = dataNcrRequested.map(
        ({ due_date }) => due_date
      );
      this.optionsData.dataNcrRequested = dataNcrRequested.map(
        ({ count }) => count
      );

      const dataNcrProcessed = await SpecialistsDashboardService.getChartData({
        from: this.dateFrom,
        to: this.dateTo,
        type: 2,
        user: this.userSelected,
        client_type_id: 2,
      });
      this.optionsData.dataNcrProcessed = dataNcrProcessed.map(
        ({ count }) => count
      );

      const dataWorkPlan = await SpecialistsDashboardService.getChartData({
        from: this.dateFrom,
        to: this.dateTo,
        type: 3,
        user: this.userSelected,
        client_type_id: 2,
      });
      this.optionsData.dataWorkPlan = dataWorkPlan.map(({ count }) => count);

      const dataDisputeRequested =
        await SpecialistsDashboardService.getChartData({
          from: this.dateFrom,
          to: this.dateTo,
          type: 4,
          user: this.userSelected,
          client_type_id: 2,
        });
      this.optionsData.dataDisputeRequested = dataDisputeRequested.map(
        ({ count }) => count
      );

      const dataDisputeProcessed =
        await SpecialistsDashboardService.getChartData({
          from: this.dateFrom,
          to: this.dateTo,
          type: 5,
          user: this.userSelected,
          client_type_id: 2,
        });
      this.optionsData.dataDisputeProcessed = dataDisputeProcessed.map(
        ({ count }) => count
      );
    },
    activateModal(num) {
      this.modalType = this.typeToText(num);
      this.showCustomModal = true;
    },
    typeToText(num) {
      switch (num) {
        case 0:
          return "ncr";
        case 1:
          return "work";
        case 2:
          return "dispute";
        default:
          return "";
      }
    },
    async updateValues() {
      await this.getCardsData();
      await this.getChartsData();
    },
  },
};
</script>

<style scoped>
.data {
  font-size: 16px;
}
.card {
  background-size: 200% auto;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}
.title {
  font-weight: 600;
  font-size: medium;
}
.square {
  border-right: 1px solid rgba(207, 190, 190, 0.5) !important;
}

.title-mobile {
  font-size: 12px !important;
}

.data-mobile {
  font-size: 16px;
}
@media (max-width: 1400px) {
  .title-mobile {
    font-size: 11px !important;

    margin-left: 15px !important;
  }

  .data {
    font-size: 14px;
  }

  .data-mobile {
    margin: 0.5px !important;
    font-size: 10.5px;
  }

  .icon {
    font-size: 60%;
  }

  .title {
    font-weight: 600;
    font-size: 13px;
  }

  .card {
    margin: 5px;
  }
}
</style>
