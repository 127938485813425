<template>
  <div>
    <!--    <pre>-->
    <!--      option {{ option }}-->
    <!--    </pre>-->
    <e-charts
      ref="line"
      auto-resize
      autoresize
      :options="dataLineChart"
      theme="theme-color"
    />
  </div>
</template>

<script>
import ECharts from "vue-echarts";
import theme from "@/views/social-network/views/dashboard2/components/chard/theme.json";
import "echarts/lib/chart/line";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";

ECharts.registerTheme("theme-color", theme);

export default {
  components: {
    ECharts,
  },
  props: {
    optionsData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dataLineChart: {
        grid: {
          width: "98%",
          left: "15px",
          right: "15px",
          containLabel: true,
        },
        dataZoom: [
          {
            type: "inside",
            id: "insideX",
            xAxisIndex: 0,
            start: 95,
            end: 100,
            zoomOnMouseWheel: false,
            moveOnMouseMove: true,
            moveOnMouseWheel: true,
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          bottom: 0,
        },
        xAxis: {
          boundaryGap: true,
          data: this.optionsData.datesXAxis,
        },
        yAxis: {
          type: "value",
          splitLine: { show: true },
          stepSize: 50,
          height: 500,
        },
        series: [
          {
            data: [],
            type: "line",
            smooth: true,
            color: "#eead92",
            center: ["50%", "50%"],
            name: "NCR Requested",
          },
          {
            data: [],
            type: "line",
            smooth: true,
            color: "#e2754a",
            name: "NCR Processed",
          },
          {
            data: [],
            type: "line",
            smooth: true,
            color: "#4bc0ea",
            name: "Works Plan",
          },
          {
            data: [],
            type: "line",
            smooth: true,
            color: "#f39897",
            name: "Dispute Requested",
          },
          {
            data: [],
            type: "line",
            smooth: true,
            color: "#e84d4d",
            name: "Dispute Processed",
          },
        ],
      },
    };
  },
  watch: {
    optionsData: {
      handler() {
        this.dataLineChart.xAxis.data = this.optionsData.datesXAxis;
        this.dataLineChart.series[0].data = this.optionsData.dataNcrRequested;
        this.dataLineChart.series[1].data = this.optionsData.dataNcrProcessed;
        this.dataLineChart.series[2].data = this.optionsData.dataWorkPlan;
        this.dataLineChart.series[3].data =
          this.optionsData.dataDisputeRequested;
        this.dataLineChart.series[4].data =
          this.optionsData.dataDisputeProcessed;
      },
      deep: true,
    },
  },
};
</script>

<style>
.echarts {
  width: 100% !important;
}
</style>
