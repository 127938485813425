<template>
  <b-modal
    v-model="isActive"
    size="lg"
    :title="modalTitle"
    title-tag="h3"
    hide-footer
    modal-class="modal-primary"
    scrollable
    @hidden="closeModal"
  >
    <b-tabs
      v-model="tabIndex"
      pills
      lazy
      nav-class="mt-2"
      active-nav-item-class="bg-primary box-shadow-info"
    >
      <template v-if="type == 'ncr'">
        <b-tab
          :title-link-class="[bgTabsNavs, 'sub-tab px-3']"
          title="NCR Requested"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="ncr-requested"
          />
          <b-table
            id="ncr-requested"
            :per-page="perPage"
            :current-page="currentPage"
            :items="tableProvider"
            :fields="fields"
            show-empty
          >
            <template #cell(name)="data">
              <router-link
                :class="textLink"
                :to="{
                  name: 'specialist-digital-dashboard',
                  params: { idClient: `${data.item.client_id}` },
                }"
                target="_blank"
                >{{ data.value }}
              </router-link>
            </template>
            <template #cell(date)="data">
              <span>{{ data.value | myGlobal }}</span>
            </template>
          </b-table>
        </b-tab>
        <b-tab
          :title-link-class="[bgTabsNavs, 'sub-tab px-3']"
          title="NCR Processed"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="ncr-procesed"
          />
          <b-table
            id="ncr-procesed"
            :per-page="perPage"
            :current-page="currentPage"
            :items="tableProvider"
            :fields="fields"
            show-empty
          >
            <template #cell(name)="data">
              <router-link
                :class="textLink"
                :to="{
                  name: 'specialist-digital-dashboard',
                  params: { idClient: `${data.item.client_id}` },
                }"
                target="_blank"
                >{{ data.value }}
              </router-link>
            </template>
            <template #cell(date)="data">
              <span>{{ data.value | myGlobal }}</span>
            </template>
          </b-table>
        </b-tab>
      </template>
      <template v-else-if="type == 'work'">
        <b-tab title="Work Plan" title-link-class="mytab">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="work-plan"
          />
          <b-table
            id="work-plan"
            :per-page="perPage"
            :current-page="currentPage"
            :items="tableProvider"
            :fields="fields"
            show-empty
          >
            <template #cell(name)="data">
              <router-link
                :class="textLink"
                :to="{
                  name: 'specialist-digital-dashboard',
                  params: { idClient: `${data.item.client_id}` },
                }"
                target="_blank"
                >{{ data.value }}
              </router-link>
            </template>
            <template #cell(date)="data">
              <span>{{ data.value | myGlobal }}</span>
            </template>
          </b-table>
        </b-tab>
      </template>
      <template v-else-if="type == 'dispute'">
        <b-tab title="Dispute Requested" title-link-class="mytab">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="dispute-all"
          />
          <b-table
            id="dispute-all"
            :per-page="perPage"
            :current-page="currentPage"
            :items="tableProvider"
            :fields="fields"
            show-empty
          >
            <template #cell(name)="data">
              <router-link
                :class="textLink"
                :to="{
                  name: 'specialist-digital-dashboard',
                  params: { idClient: `${data.item.client_id}` },
                }"
                target="_blank"
                >{{ data.value }}
              </router-link>
            </template>
            <template #cell(date)="data">
              <span>{{ data.value | myGlobal }}</span>
            </template>
          </b-table>
        </b-tab>
        <b-tab title="Dispute Processed" title-link-class="mytab">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="dispute-completed"
          />
          <b-table
            id="dispute-completed"
            :per-page="perPage"
            :current-page="currentPage"
            :items="tableProvider"
            :fields="fields"
            show-empty
          >
            <template #cell(name)="data">
              <router-link
                :class="textLink"
                :to="{
                  name: 'specialist-digital-dashboard',
                  params: { idClient: `${data.item.client_id}` },
                }"
                target="_blank"
                >{{ data.value }}
              </router-link>
            </template>
            <template #cell(date)="data">
              <span>{{ data.value | myGlobal }}</span>
            </template>
          </b-table>
        </b-tab>
      </template>
    </b-tabs>
  </b-modal>
</template>

<script>
import SpecialistsDashboardService from "@/views/specialist-digital/views/dashboard/specialists-dashboard-service";

export default {
  components: {},
  props: {
    type: String,
    params: Object,
    client_type_id: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      isActive: false,
      totalRows: 0,
      perPage: 10,
      currentPage: 1,
      tabIndex: 0,
    };
  },
  computed: {
    fields() {
      return [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "account",
          label: "Account",
        },
        {
          key: "program",
          label: "Program",
        },
        {
          key: "specialist",
          label: this.typeNum === 1 ? "Requested By" : "Specialist",
        },
        {
          key: "date",
          label: "Date",
        },
      ];
    },
    typeNum() {
      if (this.type == "ncr" && this.tabIndex == 0) {
        return 1;
      }
      if (this.type == "ncr" && this.tabIndex == 1) {
        return 2;
      }
      if (this.type == "work" && this.tabIndex == 0) {
        return 3;
      }
      if (this.type == "dispute" && this.tabIndex == 1) {
        return 4;
      }
      if (this.type == "dispute" && this.tabIndex == 0) {
        return 5;
      }
      return 0;
    },
    modalTitle() {
      switch (this.type) {
        case "ncr":
          return "NCR LIST";
        case "work":
          return "WORK PLAN LIST";
        case "dispute":
          return "DISPUTE LIST";
        default:
          return "DASHBOARD MODAL";
      }
    },
  },
  created() {
    this.isActive = true;
  },
  methods: {
    closeModal() {
      this.totalRows = 0;
      this.perPage = 10;
      this.currentPage = 1;
      this.tabIndex = 0;
      this.isActive = false;
      this.$emit("close");
    },
    async tableProvider() {
      const params = {
        from: this.params.from,
        to: this.params.to,
        user: this.params.user,
        perPage: this.perPage,
        nPage: this.currentPage,
        type: this.typeNum,
        client_type_id: this.client_type_id,
      };
      const data = await SpecialistsDashboardService.getModalData(params);
      if (data.data[0]) {
        this.totalRows = data.data[0].total;
      } else if (this.currentPage > 1) {
        this.currentPage -= 1;
      }
      return data.data;
    },
  },
};
</script>

<style></style>
