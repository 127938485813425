<template>
  <b-row class="d-flex justify-content-center">
    <div
      v-for="(item, index) in series"
      :key="index"
    >
      <div
        class="d-flex align-items-center ml-2"
      >
        <div
          :style="`width: 10px; height: 10px; background: ${item.color}; float: left`"
        />
        <span style="margin-left: 3px">{{
          item.name.replace("$", "").replace("#", "")
        }}</span>
      </div>
    </div>
  </b-row>
</template>
<script>
export default {
  props: {
    series: Array,
  },
}
</script>
